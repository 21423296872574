import React, { useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "../assets/css/Registries.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "framer-motion"; // Hook to detect when components come into view

// Import logo images
import vera from "../assets/images/vera-logo.png";
import gs from "../assets/images/gs-logo.png";
import icr from "../assets/images/icr-logo.png";
import acr from "../assets/images/acr-logo.png";

const Registries = () => {
    const slideInFromBottom = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };
    const AnimatedSection = ({ children, variants }) => {
        const controls = useAnimation();
        const ref = React.useRef();
        const inView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [inView, controls]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
            >
                {children}
            </motion.div>
        );
    };
    return (
        <div className="footer-logos">
            <div className="logos-container">
                <AnimatedSection variants={slideInFromBottom}>
                    <img src={vera} alt="Vera" className="footer-logo" />
                    <img src={icr} alt="ICR" className="footer-logo" />
                    <img src={gs} alt="Gold Standard" className="footer-logo" />
                    {/* <img src={acr} alt="American Carbon Registry" className="footer-logo" /> */}
                </AnimatedSection>
            </div>
        </div>
    )

}

export default Registries;