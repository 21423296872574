import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavbarSubpage from "../../../Layout/NavbarSubpage";
import { Container, Button, Input, Label, Form, FormGroup, Alert, Row, Col } from "reactstrap";
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompleteRegistration = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: "", password: "" });
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.VALIDATE_INVITE_TOKEN}/${token}`);
        if (response.status === 200) {
          setTokenValid(true);
        }
      } catch (error) {
        setTokenValid(false);
      }
    };

    if (token) {
      validateToken();
    }
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const inputPassword = e.target.value;
    setFormData({ ...formData, password: inputPassword });

    // Update password criteria as the user types
    setPasswordCriteria({
      length: inputPassword.length >= 8,
      lowercase: /[a-z]/.test(inputPassword),
      uppercase: /[A-Z]/.test(inputPassword),
      number: /\d/.test(inputPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(inputPassword),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await http.post(API_ENDPOINTS.COMPLETE_REGISTRATION, {
        token,
        name: formData.name,
        password: formData.password,
      });

      if (response.status === 201) {
        toast.success("Registration completed successfully!");
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (error) {
      console.error("Error completing registration:", error);
      toast.error(error.response?.data?.message || "Failed to complete registration.");
    } finally {
      setLoading(false);
    }
  };

  if (tokenValid === false) {
    return (
      <Container className="registration-container">
        <Alert color="danger">Invalid or expired invitation link.</Alert>
      </Container>
    );
  }
  return (
    <React.Fragment>
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container className="registration-container">
          <ToastContainer />
          <NavbarSubpage />
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center justify-content-center">
              <Col lg={6}>
                <h2>Complete Your Registration</h2>
                <p>Set up your account by entering your name and creating a password.</p>

                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="name">Full Name</Label>
                    <Input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={formData.password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <div className="password-requirements mt-2">
                      <p>Your password must contain:</p>
                      <ul>
                        <li style={{ color: passwordCriteria.length ? "green" : "black" }}>At least 8 characters</li>
                        <li style={{ color: passwordCriteria.lowercase ? "green" : "black" }}>Lowercase letters (a-z)</li>
                        <li style={{ color: passwordCriteria.uppercase ? "green" : "black" }}>Uppercase letters (A-Z)</li>
                        <li style={{ color: passwordCriteria.number ? "green" : "black" }}>Numbers (0-9)</li>
                        <li style={{ color: passwordCriteria.specialChar ? "green" : "black" }}>Special characters (e.g., !@#$%^&*)</li>
                      </ul>
                    </div>
                  </FormGroup>

                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Completing Registration..." : "Complete Registration"}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompleteRegistration;
