import React, { useEffect, useState, useContext } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label, Container, FormGroup } from "reactstrap";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import { ModeContext } from "../../contexts/mode/ModeContext";
import { ToastContainer, toast } from "react-toastify";
import { USER_TYPE } from "../../utils/constants/users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faFile, faExclamation, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/subaccounts.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    email: "",
    accountType: USER_TYPE.USER_REGULAR,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isTestMode } = useContext(ModeContext);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleEditModal = () => setEditModalOpen(!editModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    toggleEditModal();
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    toggleDeleteModal();
  };

  const handleRoleChange = (role) => {
    const updatedRoles = selectedUser.accountType.includes(role)
      ? selectedUser.accountType.filter((r) => r !== role) // Remove role
      : [...selectedUser.accountType, role]; // Add role
    setSelectedUser({ ...selectedUser, accountType: updatedRoles });
  };

  const handleSaveRoles = async () => {
    try {
      const response = await http.put(`${API_ENDPOINTS.UPDATE_USER}/${selectedUser._id}?isTest=${isTestMode}`, {
        accountType: selectedUser.accountType
      });

      if (response.status === 200) {
        toast.success("User roles updated successfully.");
        toggleEditModal();
        fetchUsers(); // Refresh the list after saving
      }
    } catch (error) {
      console.error("Error updating user roles:", error);
      toast.error("Failed to update user roles. Please try again.");
    }
  };


  const toggleModal = () => setModalOpen(!modalOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const fetchUsers = async () => {
    try {
      const response = await http.get(`${API_ENDPOINTS.GET_COMPANY_USERS}?isTest=${isTestMode}`);
      setUsers(response.data.users);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [isTestMode]);

  const handleInviteUser = async () => {
    try {
      const response = await http.post(`${API_ENDPOINTS.INVITE_USER}?isTest=${isTestMode}`, newUser);

      if (response.status === 200) {
        toast.success("Invitation sent successfully.");

        // Clear form and close modal
        setNewUser({ email: "", accountType: USER_TYPE.USER_REGULAR });
        toggleModal();

        // Re-fetch users to update the list
        fetchUsers();
      }
    } catch (error) {
      console.error("Error inviting user:", error);
      toast.error("Failed to send invitation. Please try again.");
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const response = await http.delete(`${API_ENDPOINTS.DELETE_USER}/${id}?isTest=${isTestMode}`);

      if (response.status === 200) {
        toggleDeleteModal();
        toast.success('User deleted successfully!')
        setUsers(users.filter(user => user._id !== id));

      }
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user. Please try again.');
    }
  };

  return (
    <div className="dashboard">
      {loading ? (
        <Container>
          <Spinner />
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2>Users</h2>
            <Button color="primary" onClick={toggleModal} style={{ borderRadius: "30px", padding: "6px 14px" }}>
              + Invite User
            </Button>
          </div>

          <Table className="subaccounts-table" responsive style={{ borderRadius: '20px' }}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Roles</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.email}</td>
                  <td>{user.accountType.join(", ")}</td>
                  <td>
                    <span className={`status-icon ${user.isActive}`}>
                      {user.isActive && (
                        <span className="status-circle fulfilled">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>

                      )}
                      {!user.isActive && (
                        <span className="status-circle placed">
                          <FontAwesomeIcon icon={faExclamation} />
                        </span>
                      )}
                    </span>
                  </td>
                  <td>
                    {/* <Button color="primary"
                      outline
                      style={{ borderRadius: '30px', padding: '6px 14px', marginRight: '3px' }}
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </Button> */}
                    <Button color="danger" style={{ borderRadius: '30px', padding: '6px 14px' }} onClick={() => handleDeleteClick(user)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Invite User Modal */}
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Invite a New User</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={newUser.email}
                    onChange={handleInputChange}
                    placeholder="Enter user email"
                  />
                </Col>
                <Col md={12} className="mt-3">
                  <Label for="accountType">User Role</Label>
                  <Input type="select" name="accountType" value={newUser.accountType} onChange={handleInputChange}>
                    <option value={USER_TYPE.USER_REGULAR}>Regular User</option>
                    <option value={USER_TYPE.USER_ADMIN}>Admin</option>
                  </Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button style={{ borderRadius: "30px", padding: "6px 14px" }} color="primary" onClick={handleInviteUser}>
                Send Invitation
              </Button>
              <Button style={{ borderRadius: "30px", padding: "6px 14px" }} color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal}>Edit User Roles</ModalHeader>
            <ModalBody>
              {selectedUser && (
                <>
                  <p><strong>Email:</strong> {selectedUser.email}</p>

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={selectedUser.accountType.includes(USER_TYPE.USER_REGULAR)}
                        onChange={() => handleRoleChange(USER_TYPE.USER_REGULAR)}
                      />
                      Regular User
                    </Label>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={selectedUser.accountType.includes(USER_TYPE.USER_ADMIN)}
                        onChange={() => handleRoleChange(USER_TYPE.USER_ADMIN)}
                      />
                      Admin
                    </Label>
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" style={{ borderRadius: '30px' }} onClick={handleSaveRoles}>
                Save Changes
              </Button>
              <Button color="secondary" style={{ borderRadius: '30px' }} onClick={toggleEditModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
            <ModalHeader toggle={toggleDeleteModal}>Delete subaccount</ModalHeader>
            <ModalBody>
              User <span style={{ fontFamily: 'monospace', background: '#f0f0f0', padding: '2px 5px', borderRadius: '5px' }}>{selectedUser?.email}</span> will be immediately deleted.
            </ModalBody>
            <ModalFooter>
              {/* <Button color="secondary" style={{ borderRadius: '10px', padding: '5px 15px' }} onClick={toggleModal}>Cancel</Button> */}
              <Button color="danger" style={{ borderRadius: '30px', padding: '5px 15px' }} onClick={() => handleDeleteUser(selectedUser._id)}>Delete User</Button>
            </ModalFooter>
          </Modal>

          <ToastContainer />
        </Container>
      )}
    </div>
  );
};

export default Users;
