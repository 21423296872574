import React from "react";
import { Col, Container, Row } from "reactstrap";

// Import partner logos
import carbonOffset from "../assets/images/feature/CO_certification_logo.jpg";
import saudiLogo from "../assets/images/feature/saudi-logo.png";
import vision2030 from "../assets/images/feature/vision2030.png";
import esgSummit from "../assets/images/feature/esg-summit.png";
import cyl from "../assets/images/cyl-croped.png";
import circle from "../assets/images/circle.png";
import "../assets/css/Services.css";

const Services = () => {
  return (
    <section 
      id="process" 
      dir="rtl"
      className="services-section " >
      <img src={circle} alt="دائرة" className="bg-image circle-services" />

      <Container>
        {/* العنوان الرئيسي */}
        <Row className="justify-content-center text-center">
          <Col lg={10}>
            <h2>
              الامتثال لرؤية <span className="bold">2030</span> للمملكة 
              في تقليل انبعاثات الكربون الجماعية
            </h2>
            <p className="text-muted mt-3">
              نحن نعتمد على أحدث العلوم لإنشاء مجموعة متنوعة من المشاريع المصممة خصيصًا
              لتقليل المخاطر وتحقيق أقصى تأثير مناخي. الآن، يمكنك الاستفادة من هذه
              الاستراتيجية المتطورة بتكلفة ميسورة.
            </p>
          </Col>
        </Row>

        {/* شعارات الشركاء */}
        <Row className="justify-content-center mt-4">
          <Col xs={6} sm={3} className="text-center">
            <img src={carbonOffset} alt="تعويض الكربون" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={saudiLogo} alt="رؤية السعودية" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={vision2030} alt="رؤية 2030" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={esgSummit} alt="قمة ESG" className="partner-logo" style={{ height: '100px' }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
