import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import PaymentComponent from '../../components/PaymentComponent';
import { useNavigate } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../contexts/mode/ModeContext';
import OrderContext from '../../contexts/order/OrderContext';
import UserContext from "../../contexts/user/UserContext";
import sar from "../../assets/images/flags/Saudi_Riyal_Symbol-1.png";

const CheckoutPage = () => {
    const { isTestMode } = useContext(ModeContext);
    const { orderData, clearOrderData } = useContext(OrderContext);
    const { user } = useContext(UserContext);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();


    if (!orderData) {
        return <p>Error: Order data is missing. Please go back and try again.</p>;
    }

    const handlePlaceSandboxOrder = async () => {
        try {
            const finalOrderData = {
                ...orderData,
                portfolioId: orderData.portfolio.id,
                paymentId: null, // No payment ID for Testing Mode
            };

            const response = await http.post(`${API_ENDPOINTS.SUBMIT_ORDER}?isTest=true`, finalOrderData);

            if (response.status === 201) {
                clearOrderData();
                navigate(`/profile/order/success/${response.data.orderId}`); // Redirect to success page
            } else {
                setError('Failed to place test order. Please try again.');
            }
        } catch (err) {
            console.error('Error placing test order:', err);
            setError('Error placing test order. Please try again.');
        }
    };

    return (
        <Container fluid className="dashboard">
            <Row className="justify-content-center">
                <Col>
                    <h2 className="mt-4">Order Confirmation</h2>
                    <Card style={{ borderRadius: '15px', marginTop: '20px' }}>
                        <CardBody>
                            <h4 className="mt-4">Order summary</h4>
                            <div className="d-flex justify-content-between">
                                <span>Carbon credits</span>
                                <span>{orderData.kg_amount} kg</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Portfolio</span>
                                <span>{orderData.portfolio.name}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>Description</span>
                                <span>{orderData.description}</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <strong>Order total</strong>
                                <strong><img src={sar} alt="SAR Symbol" className="sar-symbol" /> {orderData.totalPrice.toFixed(2)}</strong>
                            </div>
                            {isTestMode ? (
                                <>
                                    <Alert color="danger" className="mt-4">
                                        <strong>Testing Mode</strong>
                                        <p>
                                            This is Testing Mode, meaning no charges will be applied, and no real CO₂e credits will be purchased.{' '}
                                        </p>
                                    </Alert>
                                    <Button
                                        color="primary"
                                        block
                                        className="mt-3"
                                        onClick={handlePlaceSandboxOrder}
                                        style={{ borderRadius: '10px' }}
                                    >
                                        Place test order
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h4 className="mt-4">Payment method</h4>
                                    <PaymentComponent amount={orderData.totalPrice} companyId={user.companyId} protfolio={orderData.portfolio.name} />
                                </>
                            )}
                            {error && <Alert color="danger" className="mt-3">{error}</Alert>}
                            {success && <Alert color="success" className="mt-3">{success}</Alert>}
                            <p className="text-center mt-3">
                                By paying, you agree to the <a href="/terms">Terms of Service</a> and{' '}
                                <a href="/privacy">Privacy Policy</a>.
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CheckoutPage;
