import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../assets/css/PricingSection.css"; // Ensure you style the section properly
import circle from "../assets/images/circle-rev.png";
import sar from "../assets/images/flags/Saudi_Riyal_Symbol-1.png";

const PricingData = [
  {
    title: "Standard",
    price: "80",
    currency: sar,
    subtitle: "/tonne",
    gradient: true, // Used to apply gradient styling
    features: [
      "Dashboard & web ordering",
      "Diversified offset portfolio",
      "Tech support",
      "Integrations",
      "Basic support"
    ],
    buttonLabel: "Get started for free",
    buttonLink: "/signup",
  },
  {
    title: "Enterprise",
    price: "Custom",
    subtitle: "",
    gradient: false,
    features: [
      "Customized estimates",
      "Endpoints",
      "Customized portfolio",
      "24/7 support",
      "Basic support"
    ],
    buttonLabel: "Contact Us",
    buttonLink: "/contact",
  },
];

const Pricing = () => {
  return (
    <section className="pricing-section" id="pricing">
      <Container>
        <Row className="justify-content-center">
          <Col lg={7} className="text-center">
            <h3>Our pay-as-you-go pricing</h3>
          </Col>
        </Row>

        <Row className="pricing-cards-container">
          {PricingData.map((plan, index) => (
            <Col key={index} lg={6} md={8}>
              <div className={`pricing-box ${plan.gradient ? "gradient-background" : ""}`}>
                <p className="plan-title">{plan.title}</p>
                <h1 className="plan-price">
                  {plan.currency ? <img src={sar} alt="SAR Symbol" className="sar-symbol" /> : <></>} {plan.price} <span className="plan-subtitle">{plan.subtitle}</span>
                </h1>
                <Link to={plan.buttonLink} className="pricing-button" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}>
                  {plan.buttonLabel}
                </Link>
                <ul className="features-list">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </div>
            </Col>
          ))}
        </Row>
        <img src={circle} alt="Cylinder" className="bg-image circle-pricing" style={{ width: '100px', right: '1px' }} />

      </Container>
    </section>
  );
};

export default Pricing;
